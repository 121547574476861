var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../../../config/frontend/nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["front/Base.jinja"] = require( "front/Base.jinja" );
dependencies["front/utils/macros.jinja"] = require( "front/utils/macros.jinja" );
dependencies["front/components/ButtonIcon/ButtonIcon.jinja"] = require( "front/components/ButtonIcon/ButtonIcon.jinja" );
dependencies["front/components/StyleTag/StyleTag.jinja"] = require( "front/components/StyleTag/StyleTag.jinja" );
dependencies["front/components/Locations/Locations.jinja"] = require( "front/components/Locations/Locations.jinja" );




var shim = require("/var/www/mosbrew.ru/application/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["assets/app/front/pages/LocationsPopup/LocationsPopup.jinja"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("front/Base.jinja", true, "assets/app/front/pages/LocationsPopup/LocationsPopup.jinja", false, function(t_3,t_2) {
if(t_3) { cb(t_3); return; }
parentTemplate = t_2
for(var t_1 in parentTemplate.blocks) {
context.addBlock(t_1, parentTemplate.blocks[t_1]);
}
output += "\n\n";
env.getTemplate("front/utils/macros.jinja", false, "assets/app/front/pages/LocationsPopup/LocationsPopup.jinja", false, function(t_5,t_4) {
if(t_5) { cb(t_5); return; }
t_4.getExported(function(t_6,t_4) {
if(t_6) { cb(t_6); return; }
if(Object.prototype.hasOwnProperty.call(t_4, "svgSprite")) {
var t_7 = t_4.svgSprite;
} else {
cb(new Error("cannot import 'svgSprite'")); return;
}
context.setVariable("svgSprite", t_7);
output += "\n";
env.getTemplate("front/components/ButtonIcon/ButtonIcon.jinja", false, "assets/app/front/pages/LocationsPopup/LocationsPopup.jinja", false, function(t_9,t_8) {
if(t_9) { cb(t_9); return; }
t_8.getExported(function(t_10,t_8) {
if(t_10) { cb(t_10); return; }
if(Object.prototype.hasOwnProperty.call(t_8, "ButtonIcon")) {
var t_11 = t_8.ButtonIcon;
} else {
cb(new Error("cannot import 'ButtonIcon'")); return;
}
context.setVariable("ButtonIcon", t_11);
output += "\n";
env.getTemplate("front/components/StyleTag/StyleTag.jinja", false, "assets/app/front/pages/LocationsPopup/LocationsPopup.jinja", false, function(t_13,t_12) {
if(t_13) { cb(t_13); return; }
t_12.getExported(function(t_14,t_12) {
if(t_14) { cb(t_14); return; }
if(Object.prototype.hasOwnProperty.call(t_12, "StyleTag")) {
var t_15 = t_12.StyleTag;
} else {
cb(new Error("cannot import 'StyleTag'")); return;
}
context.setVariable("StyleTag", t_15);
output += "\n";
env.getTemplate("front/components/Locations/Locations.jinja", false, "assets/app/front/pages/LocationsPopup/LocationsPopup.jinja", false, function(t_17,t_16) {
if(t_17) { cb(t_17); return; }
t_16.getExported(function(t_18,t_16) {
if(t_18) { cb(t_18); return; }
if(Object.prototype.hasOwnProperty.call(t_16, "Locations")) {
var t_19 = t_16.Locations;
} else {
cb(new Error("cannot import 'Locations'")); return;
}
context.setVariable("Locations", t_19);
output += "\n\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("static"))(env, context, frame, runtime, function(t_21,t_20) {
if(t_21) { cb(t_21); return; }
output += t_20;
output += "\n\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("title"))(env, context, frame, runtime, function(t_23,t_22) {
if(t_23) { cb(t_23); return; }
output += t_22;
output += "\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("ogtitle"))(env, context, frame, runtime, function(t_25,t_24) {
if(t_25) { cb(t_25); return; }
output += t_24;
output += "\n\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("topNav"))(env, context, frame, runtime, function(t_27,t_26) {
if(t_27) { cb(t_27); return; }
output += t_26;
output += "\n\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("body_class"))(env, context, frame, runtime, function(t_29,t_28) {
if(t_29) { cb(t_29); return; }
output += t_28;
output += "\n\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("popup"))(env, context, frame, runtime, function(t_31,t_30) {
if(t_31) { cb(t_31); return; }
output += t_30;
output += "\n\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("data"))(env, context, frame, runtime, function(t_33,t_32) {
if(t_33) { cb(t_33); return; }
output += t_32;
output += "\n\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("libs"))(env, context, frame, runtime, function(t_35,t_34) {
if(t_35) { cb(t_35); return; }
output += t_34;
output += "\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})})})})})})})})})})})})})})})})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_static(env, context, frame, runtime, cb) {
var lineno = 7;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
context.getSuper(env, "static", b_static, frame, runtime, function(t_36,hole_91) {
if(t_36) { cb(t_36); return; }
hole_91 = runtime.markSafe(hole_91);
output += "\n    ";
output += runtime.suppressValue(hole_91, env.opts.autoescape);
output += "\n\n    <link href='https://api.mapbox.com/mapbox-gl-js/v0.44.2/mapbox-gl.css' rel='stylesheet' />\n";
cb(null, output);
});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_title(env, context, frame, runtime, cb) {
var lineno = 13;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
output += " • ";
output += runtime.suppressValue((lineno = 13, colno = 24, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Где попробовать"])), env.opts.autoescape);
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_ogtitle(env, context, frame, runtime, cb) {
var lineno = 14;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
output += " • ";
output += runtime.suppressValue((lineno = 14, colno = 26, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Где попробовать"])), env.opts.autoescape);
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_topNav(env, context, frame, runtime, cb) {
var lineno = 16;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
output += "\n    ";
output += runtime.suppressValue((lineno = 17, colno = 13, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "TopNav"), "TopNav", context, [runtime.makeKeywordArgs({"classes": "TopNav--floatNav","currentUrl": runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "request")),"path")})])), env.opts.autoescape);
output += "\n";
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_body_class(env, context, frame, runtime, cb) {
var lineno = 20;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
output += "LocationsPage";
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_popup(env, context, frame, runtime, cb) {
var lineno = 22;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
output += "\n    <div class=\"LocationsPopup LocationsPopup--beer ";
if(!runtime.contextOrFrameLookup(context, frame, "server")) {
output += "LocationsPopup--toAnimate";
;
}
output += "\">\n        <link href='https://api.mapbox.com/mapbox-gl-js/v0.44.2/mapbox-gl.css' rel='stylesheet' />\n\n        <div class=\"LocationsPopup-floatNav\"></div>\n        <div class=\"LocationsPopup-close\">";
output += runtime.suppressValue((lineno = 27, colno = 55, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "ButtonIcon"), "ButtonIcon", context, [])), env.opts.autoescape);
output += "</div>\n\n        <div class=\"LocationsPopup-content\">\n            <div class=\"LocationsPopup-menu\">\n                <div class=\"LocationsPopup-head\">\n                    <header class=\"LocationsPopup-header\">";
output += runtime.suppressValue((lineno = 32, colno = 62, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Где попробовать?"])), env.opts.autoescape);
output += "</header>\n\n                    ";
if(env.getFilter("length").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "beer")),"brands")) > 0 && env.getFilter("length").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "ciders")),"brands")) > 0) {
output += "<div class=\"LocationsPopup-categories\">\n                        <div class=\"LocationsPopup-category\">\n                            <input id=\"LocationsPopup-category--beer\" type=\"radio\" name=\"category\" value=\"beer\" checked=\"checked\" />\n                            <label for=\"LocationsPopup-category--beer\">";
output += runtime.suppressValue((lineno = 38, colno = 75, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Пиво"])), env.opts.autoescape);
output += "</label>\n                        </div>\n\n                        <div class=\"LocationsPopup-category\">\n                            <input id=\"LocationsPopup-category--ciders\" type=\"radio\" name=\"category\" value=\"ciders\" />\n                            <label for=\"LocationsPopup-category--ciders\">";
output += runtime.suppressValue((lineno = 43, colno = 77, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Сидр"])), env.opts.autoescape);
output += "</label>\n                        </div>\n                    </div>\n                    ";
;
}
output += "</div>\n\n                ";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "beer")),"brands")) {
output += "<div class=\"LocationsPopup-filters LocationsPopup-filters--beer\">\n                    <div class=\"LocationsPopup-subCategories\">\n                        <header class=\"LocationsPopup-subCategoriesHeader\">";
output += runtime.suppressValue((lineno = 52, colno = 79, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Производство"])), env.opts.autoescape);
output += "</header>\n\n                        <div class=\"LocationsPopup-subCategoriesList\">\n                            ";
frame = frame.push();
var t_39 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "beer")),"subCategories");
if(t_39) {t_39 = runtime.fromIterator(t_39);
var t_38 = t_39.length;
for(var t_37=0; t_37 < t_39.length; t_37++) {
var t_40 = t_39[t_37];
frame.set("s", t_40);
frame.set("loop.index", t_37 + 1);
frame.set("loop.index0", t_37);
frame.set("loop.revindex", t_38 - t_37);
frame.set("loop.revindex0", t_38 - t_37 - 1);
frame.set("loop.first", t_37 === 0);
frame.set("loop.last", t_37 === t_38 - 1);
frame.set("loop.length", t_38);
output += "\n                                <div class=\"LocationsPopup-subCategory\">\n                                    <input id=\"LocationsPopup-subCategory--beer-";
output += runtime.suppressValue(runtime.memberLookup((t_40),"slug"), env.opts.autoescape);
output += "\" value=\"";
output += runtime.suppressValue(runtime.memberLookup((t_40),"slug"), env.opts.autoescape);
output += "\" type=\"checkbox\" checked=\"checked\">\n                                    <span class=\"LocationsPopup-inputCheckbox\">";
output += runtime.suppressValue((lineno = 58, colno = 91, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "svgSprite"), "svgSprite", context, ["checkMark"])), env.opts.autoescape);
output += "</span>\n                                    <label for=\"LocationsPopup-subCategory--beer-";
output += runtime.suppressValue(runtime.memberLookup((t_40),"slug"), env.opts.autoescape);
output += "\">";
output += runtime.suppressValue(runtime.memberLookup((t_40),"title"), env.opts.autoescape);
output += "</label>\n                                </div>\n                            ";
;
}
}
frame = frame.pop();
output += "\n                        </div>\n                    </div>\n\n                    <div class=\"LocationsPopup-brands\">\n                        <header class=\"LocationsPopup-brandsHeader\">";
output += runtime.suppressValue((lineno = 66, colno = 72, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Бренды"])), env.opts.autoescape);
output += "</header>\n\n                        <div class=\"LocationsPopup-brandsList\">\n                            ";
frame = frame.push();
var t_43 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "beer")),"brands");
if(t_43) {t_43 = runtime.fromIterator(t_43);
var t_42 = t_43.length;
for(var t_41=0; t_41 < t_43.length; t_41++) {
var t_44 = t_43[t_41];
frame.set("s", t_44);
frame.set("loop.index", t_41 + 1);
frame.set("loop.index0", t_41);
frame.set("loop.revindex", t_42 - t_41);
frame.set("loop.revindex0", t_42 - t_41 - 1);
frame.set("loop.first", t_41 === 0);
frame.set("loop.last", t_41 === t_42 - 1);
frame.set("loop.length", t_42);
output += "\n                                ";
output += runtime.suppressValue((lineno = 70, colno = 43, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "StyleTag"), "StyleTag", context, [t_44])), env.opts.autoescape);
output += "\n                            ";
;
}
}
frame = frame.pop();
output += "\n                        </div>\n                    </div>\n                </div>\n                ";
;
}
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "ciders")),"brands")) {
output += "<div class=\"LocationsPopup-filters LocationsPopup-filters--ciders\">\n                    <div class=\"LocationsPopup-subCategories\">\n                        <header class=\"LocationsPopup-subCategoriesHeader\">";
output += runtime.suppressValue((lineno = 80, colno = 79, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Производство"])), env.opts.autoescape);
output += "</header>\n\n                        <div class=\"LocationsPopup-subCategoriesList\">\n                            ";
frame = frame.push();
var t_47 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "ciders")),"subCategories");
if(t_47) {t_47 = runtime.fromIterator(t_47);
var t_46 = t_47.length;
for(var t_45=0; t_45 < t_47.length; t_45++) {
var t_48 = t_47[t_45];
frame.set("s", t_48);
frame.set("loop.index", t_45 + 1);
frame.set("loop.index0", t_45);
frame.set("loop.revindex", t_46 - t_45);
frame.set("loop.revindex0", t_46 - t_45 - 1);
frame.set("loop.first", t_45 === 0);
frame.set("loop.last", t_45 === t_46 - 1);
frame.set("loop.length", t_46);
output += "\n                                <div class=\"LocationsPopup-subCategory\">\n                                    <input id=\"LocationsPopup-subCategory--ciders";
output += runtime.suppressValue(runtime.memberLookup((t_48),"slug"), env.opts.autoescape);
output += "\" value=\"";
output += runtime.suppressValue(runtime.memberLookup((t_48),"slug"), env.opts.autoescape);
output += "\" type=\"checkbox\" checked=\"checked\">\n                                    <span class=\"LocationsPopup-inputCheckbox\">";
output += runtime.suppressValue((lineno = 86, colno = 91, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "svgSprite"), "svgSprite", context, ["checkMark"])), env.opts.autoescape);
output += "</span>\n                                    <label for=\"LocationsPopup-subCategory--ciders-";
output += runtime.suppressValue(runtime.memberLookup((t_48),"slug"), env.opts.autoescape);
output += "\">";
output += runtime.suppressValue(runtime.memberLookup((t_48),"title"), env.opts.autoescape);
output += "</label>\n                                </div>\n                            ";
;
}
}
frame = frame.pop();
output += "\n                        </div>\n                    </div>\n\n                    <div class=\"LocationsPopup-brands\">\n                        <header class=\"LocationsPopup-brandsHeader\">";
output += runtime.suppressValue((lineno = 94, colno = 72, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Бренды"])), env.opts.autoescape);
output += "</header>\n\n                        <div class=\"LocationsPopup-brandsList\">\n                            ";
frame = frame.push();
var t_51 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "ciders")),"brands");
if(t_51) {t_51 = runtime.fromIterator(t_51);
var t_50 = t_51.length;
for(var t_49=0; t_49 < t_51.length; t_49++) {
var t_52 = t_51[t_49];
frame.set("s", t_52);
frame.set("loop.index", t_49 + 1);
frame.set("loop.index0", t_49);
frame.set("loop.revindex", t_50 - t_49);
frame.set("loop.revindex0", t_50 - t_49 - 1);
frame.set("loop.first", t_49 === 0);
frame.set("loop.last", t_49 === t_50 - 1);
frame.set("loop.length", t_50);
output += "\n                                ";
output += runtime.suppressValue((lineno = 98, colno = 43, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "StyleTag"), "StyleTag", context, [t_52])), env.opts.autoescape);
output += "\n                            ";
;
}
}
frame = frame.pop();
output += "\n                        </div>\n                    </div>\n                </div>\n                ";
;
}
output += "</div>\n\n            <div class=\"LocationsPopup-locations\">\n                ";
output += runtime.suppressValue((lineno = 107, colno = 28, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "Locations"), "Locations", context, [runtime.makeKeywordArgs({"classes": "Locations--popup","type": "popup"})])), env.opts.autoescape);
output += "\n            </div>\n\n            <div class=\"LocationsPopup-loader\">\n                <div class=\"LocationsPopup-loader-inner\">\n                   <div class=\"ball\"></div>\n                   <div class=\"ball\"></div>\n                   <div class=\"ball\"></div>\n                   <div class=\"ball\"></div>\n                   <div class=\"ball\"></div>\n                   <div class=\"ball\"></div>\n                   <div class=\"ball\"></div>\n                   <div class=\"ball\"></div>\n                   <div class=\"ball\"></div>\n                   <div class=\"ball\"></div>\n                </div>\n            </div>\n        </div>\n\n        ";
if(!runtime.contextOrFrameLookup(context, frame, "server")) {
output += "<script src='https://api.mapbox.com/mapbox-gl-js/v0.44.2/mapbox-gl.js'></script>";
;
}
output += "\n    </div>\n";
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_data(env, context, frame, runtime, cb) {
var lineno = 130;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
context.getSuper(env, "data", b_data, frame, runtime, function(t_53,hole_97) {
if(t_53) { cb(t_53); return; }
hole_97 = runtime.markSafe(hole_97);
output += "\n    ";
output += runtime.suppressValue(hole_97, env.opts.autoescape);
output += "\n\n    <script>\n        app.data.brands = ";
output += runtime.suppressValue(env.getFilter("safe").call(context, env.getFilter("jsonify").call(context, runtime.contextOrFrameLookup(context, frame, "brands"))), env.opts.autoescape);
output += ";\n        app.data.beer = ";
output += runtime.suppressValue(env.getFilter("safe").call(context, env.getFilter("jsonify").call(context, runtime.contextOrFrameLookup(context, frame, "beer"))), env.opts.autoescape);
output += ";\n        app.data.ciders = ";
output += runtime.suppressValue(env.getFilter("safe").call(context, env.getFilter("jsonify").call(context, runtime.contextOrFrameLookup(context, frame, "ciders"))), env.opts.autoescape);
output += ";\n        ";
output += "\n    </script>\n";
cb(null, output);
});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_libs(env, context, frame, runtime, cb) {
var lineno = 141;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
context.getSuper(env, "libs", b_libs, frame, runtime, function(t_54,hole_98) {
if(t_54) { cb(t_54); return; }
hole_98 = runtime.markSafe(hole_98);
output += "\n    <script src='https://api.mapbox.com/mapbox-gl-js/v0.44.2/mapbox-gl.js'></script>\n    ";
output += runtime.suppressValue(hole_98, env.opts.autoescape);
output += "\n";
cb(null, output);
});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
b_static: b_static,
b_title: b_title,
b_ogtitle: b_ogtitle,
b_topNav: b_topNav,
b_body_class: b_body_class,
b_popup: b_popup,
b_data: b_data,
b_libs: b_libs,
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["assets/app/front/pages/LocationsPopup/LocationsPopup.jinja"] , dependencies)
var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], {"dev":false,"autoescape":true,"throwOnUndefined":false,"trimBlocks":false,"lstripBlocks":false});
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../../../config/frontend/nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["front/utils/macros.jinja"] = require( "front/utils/macros.jinja" );
dependencies["front/components/Subscribe/Subscribe.jinja"] = require( "front/components/Subscribe/Subscribe.jinja" );




var shim = require("/var/www/mosbrew.ru/application/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["assets/app/front/components/Footer/Footer.jinja"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("front/utils/macros.jinja", false, "assets/app/front/components/Footer/Footer.jinja", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
if(Object.prototype.hasOwnProperty.call(t_1, "svgSprite")) {
var t_4 = t_1.svgSprite;
} else {
cb(new Error("cannot import 'svgSprite'")); return;
}
context.setVariable("svgSprite", t_4);
output += "\n";
env.getTemplate("front/components/Subscribe/Subscribe.jinja", false, "assets/app/front/components/Footer/Footer.jinja", false, function(t_6,t_5) {
if(t_6) { cb(t_6); return; }
t_5.getExported(function(t_7,t_5) {
if(t_7) { cb(t_7); return; }
if(Object.prototype.hasOwnProperty.call(t_5, "Subscribe")) {
var t_8 = t_5.Subscribe;
} else {
cb(new Error("cannot import 'Subscribe'")); return;
}
context.setVariable("Subscribe", t_8);
output += "\n\n<div class=\"Footer\">\n\n    <div class=\"Footer-subscribe\">\n        <div class=\"Footer-subscribe-inner\">\n            ";
output += runtime.suppressValue((lineno = 7, colno = 24, runtime.callWrap(t_8, "Subscribe", context, [runtime.makeKeywordArgs({"classes": "Subscribe--footer"})])), env.opts.autoescape);
output += "\n        </div>\n    </div>\n\n    <div class=\"Footer-inner\">\n        <div class=\"Footer-background\">\n            <div class=\"Footer-background-logo\">\n                <svg viewBox=\"0 0 192 295\" preserveAspectRatio=\"xMinYMid\">\n                    <path fill=\"#fff\" d=\"M0 295V.6l96.9 106.8L171.6 23 192 0v295H0zm23-22.7h146V61l-71.8 81.2L23 60.5v211.8z\" opacity=\".038\"/>\n                </svg>\n            </div>\n        </div>\n        <div class=\"Footer-logo\">\n            <a class=\"Footer-logoLink\" href=\"";
output += runtime.suppressValue((lineno = 20, colno = 51, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url"), "url", context, ["front:index"])), env.opts.autoescape);
output += "\">";
output += runtime.suppressValue((lineno = 20, colno = 83, runtime.callWrap(t_4, "svgSprite", context, ["footerLogo"])), env.opts.autoescape);
output += "</a>\n            <div class=\"Footer-social\">\n                <a class=\"Footer-socialLink\" href=\"https://t.me/mosbrew\" target=\"_blank\" rel=\"noopener noreferrer nofollow\">";
output += runtime.suppressValue((lineno = 22, colno = 136, runtime.callWrap(t_4, "svgSprite", context, ["tg"])), env.opts.autoescape);
output += "</a>\n                ";
output += "\n                <a class=\"Footer-socialLink\" href=\"https://vk.com/territorija_mosbrew\" target=\"_blank\" rel=\"noopener noreferrer nofollow\">";
output += runtime.suppressValue((lineno = 24, colno = 150, runtime.callWrap(t_4, "svgSprite", context, ["vk"])), env.opts.autoescape);
output += "</a>\n                <a class=\"Footer-socialLink\" href=\"https://dzen.ru/mosbrew\" target=\"_blank\" rel=\"noopener noreferrer nofollow\">";
output += runtime.suppressValue((lineno = 25, colno = 139, runtime.callWrap(t_4, "svgSprite", context, ["dzen"])), env.opts.autoescape);
output += "</a>\n                ";
output += "\n                ";
output += "\n            </div>\n            <div class=\"Footer-copy\">";
output += runtime.suppressValue(env.getFilter("safe").call(context, (lineno = 29, colno = 41, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Сделано в <a href=\"http://astroshock.com/\" target=\"_blank\">Астрошоке</a>"]))), env.opts.autoescape);
output += "</div>\n        </div>\n\n        <div class=\"Footer-company\">\n            <div class=\"Footer-header\">";
output += runtime.suppressValue((lineno = 33, colno = 43, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Компания"])), env.opts.autoescape);
output += "</div>\n            <a class=\"Footer-link\" href=\"";
output += runtime.suppressValue((lineno = 34, colno = 47, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url"), "url", context, ["front:about"])), env.opts.autoescape);
output += "\">";
output += runtime.suppressValue((lineno = 34, colno = 71, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["О нас"])), env.opts.autoescape);
output += "</a>\n            <a class=\"Footer-link\" href=\"";
output += runtime.suppressValue((lineno = 35, colno = 47, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url"), "url", context, ["front:history"])), env.opts.autoescape);
output += "\">";
output += runtime.suppressValue((lineno = 35, colno = 73, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["История"])), env.opts.autoescape);
output += "</a>\n            <a class=\"Footer-link\" href=\"";
output += runtime.suppressValue((lineno = 36, colno = 47, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url"), "url", context, ["front:principles"])), env.opts.autoescape);
output += "\">";
output += runtime.suppressValue((lineno = 36, colno = 76, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Принципы"])), env.opts.autoescape);
output += "</a>\n            <a class=\"Footer-link\" href=\"";
output += runtime.suppressValue((lineno = 37, colno = 47, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url"), "url", context, ["front:people"])), env.opts.autoescape);
output += "\">";
output += runtime.suppressValue((lineno = 37, colno = 72, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Люди"])), env.opts.autoescape);
output += "</a>\n            <a class=\"Footer-link\" href=\"";
output += runtime.suppressValue((lineno = 38, colno = 47, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url"), "url", context, ["front:production"])), env.opts.autoescape);
output += "\">";
output += runtime.suppressValue((lineno = 38, colno = 76, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Производство"])), env.opts.autoescape);
output += "</a>\n            <a class=\"Footer-link Footer-link--ru\" href=\"";
output += runtime.suppressValue((lineno = 39, colno = 63, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url"), "url", context, ["front:career"])), env.opts.autoescape);
output += "\">";
output += runtime.suppressValue((lineno = 39, colno = 88, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Карьера"])), env.opts.autoescape);
output += "</a>\n            <a class=\"Footer-link\" href=\"";
output += runtime.suppressValue((lineno = 40, colno = 47, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url"), "url", context, ["front:contact"])), env.opts.autoescape);
output += "\">";
output += runtime.suppressValue((lineno = 40, colno = 73, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Контакты"])), env.opts.autoescape);
output += "</a>\n            <div class=\"Footer-link Footer-link--not-ru\">&nbsp;</div>\n        </div>\n\n        <div class=\"Footer-brandy\">\n            <div class=\"Footer-header\">";
output += runtime.suppressValue((lineno = 45, colno = 43, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Бренды"])), env.opts.autoescape);
output += "</div>\n            <a class=\"Footer-link\" href=\"";
output += runtime.suppressValue((lineno = 46, colno = 47, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url"), "url", context, ["front:brands-category",runtime.makeKeywordArgs({"args": ["beer"]})])), env.opts.autoescape);
output += "\">";
output += runtime.suppressValue((lineno = 46, colno = 96, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Пиво"])), env.opts.autoescape);
output += "</a>\n            <a class=\"Footer-link\" href=\"";
output += runtime.suppressValue((lineno = 47, colno = 47, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url"), "url", context, ["front:brands-category",runtime.makeKeywordArgs({"args": ["ciders"]})])), env.opts.autoescape);
output += "\">";
output += runtime.suppressValue((lineno = 47, colno = 98, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Сидр"])), env.opts.autoescape);
output += "</a>\n            <a class=\"Footer-link\" href=\"";
output += runtime.suppressValue((lineno = 48, colno = 47, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url"), "url", context, ["front:brands-category",runtime.makeKeywordArgs({"args": ["beverages"]})])), env.opts.autoescape);
output += "\">";
output += runtime.suppressValue((lineno = 48, colno = 101, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Напитки"])), env.opts.autoescape);
output += "</a>\n        </div>\n\n        <div class=\"Footer-media\">\n            <div class=\"Footer-header\">";
output += runtime.suppressValue((lineno = 52, colno = 43, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Медиа"])), env.opts.autoescape);
output += "</div>\n\n            ";
frame = frame.push();
var t_11 = runtime.contextOrFrameLookup(context, frame, "sections");
if(t_11) {t_11 = runtime.fromIterator(t_11);
var t_10 = t_11.length;
for(var t_9=0; t_9 < t_11.length; t_9++) {
var t_12 = t_11[t_9];
frame.set("s", t_12);
frame.set("loop.index", t_9 + 1);
frame.set("loop.index0", t_9);
frame.set("loop.revindex", t_10 - t_9);
frame.set("loop.revindex0", t_10 - t_9 - 1);
frame.set("loop.first", t_9 === 0);
frame.set("loop.last", t_9 === t_10 - 1);
frame.set("loop.length", t_10);
output += "\n                <a class=\"Footer-link\" href=\"";
output += runtime.suppressValue((lineno = 55, colno = 51, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url"), "url", context, ["posts:section",runtime.makeKeywordArgs({"args": [runtime.memberLookup((t_12),"slug")]})])), env.opts.autoescape);
output += "\">";
output += runtime.suppressValue(runtime.memberLookup((t_12),"title"), env.opts.autoescape);
output += "</a>\n            ";
;
}
}
frame = frame.pop();
output += "\n        </div>\n\n        <div class=\"Footer-languagesCol\">\n            <div class=\"Footer-languages\">\n                <a class=\"Footer-languageLink ";
output += runtime.suppressValue((lineno = 61, colno = 55, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "active"), "active", context, [runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "request")),"path"),"^(?!(/zh-hans/|/zh-tw/|/en/))"])), env.opts.autoescape);
output += "\" href=\"";
output += runtime.suppressValue((lineno = 61, colno = 126, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "other_lang"), "other_lang", context, [runtime.contextOrFrameLookup(context, frame, "request"),"ru"])), env.opts.autoescape);
output += "\">";
output += runtime.suppressValue((lineno = 61, colno = 158, runtime.callWrap(t_4, "svgSprite", context, ["russianIcon"])), env.opts.autoescape);
output += "</a>\n                <a class=\"Footer-languageLink ";
output += runtime.suppressValue((lineno = 62, colno = 55, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "active"), "active", context, [runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "request")),"path"),"^/zh-hans/"])), env.opts.autoescape);
output += "\" href=\"";
output += runtime.suppressValue((lineno = 62, colno = 107, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "other_lang"), "other_lang", context, [runtime.contextOrFrameLookup(context, frame, "request"),"zh-hans"])), env.opts.autoescape);
output += "\">";
output += runtime.suppressValue((lineno = 62, colno = 144, runtime.callWrap(t_4, "svgSprite", context, ["chineseIcon"])), env.opts.autoescape);
output += "</a>\n                <a class=\"Footer-languageLink ";
output += runtime.suppressValue((lineno = 63, colno = 55, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "active"), "active", context, [runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "request")),"path"),"^/zh-tw/"])), env.opts.autoescape);
output += "\" href=\"";
output += runtime.suppressValue((lineno = 63, colno = 105, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "other_lang"), "other_lang", context, [runtime.contextOrFrameLookup(context, frame, "request"),"zh-tw"])), env.opts.autoescape);
output += "\">";
output += runtime.suppressValue((lineno = 63, colno = 140, runtime.callWrap(t_4, "svgSprite", context, ["taiwanIcon"])), env.opts.autoescape);
output += "</a>\n                <a class=\"Footer-languageLink ";
output += runtime.suppressValue((lineno = 64, colno = 55, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "active"), "active", context, [runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "request")),"path"),"^/en/"])), env.opts.autoescape);
output += "\" href=\"";
output += runtime.suppressValue((lineno = 64, colno = 102, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "other_lang"), "other_lang", context, [runtime.contextOrFrameLookup(context, frame, "request"),"en"])), env.opts.autoescape);
output += "\">";
output += runtime.suppressValue((lineno = 64, colno = 134, runtime.callWrap(t_4, "svgSprite", context, ["englishIcon"])), env.opts.autoescape);
output += "</a>\n            </div>\n\n            <div class=\"Footer-links\">\n                <a class=\"Footer-link\" href=\"";
output += runtime.suppressValue((lineno = 68, colno = 51, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url"), "url", context, ["front:confidential"])), env.opts.autoescape);
output += "\">";
output += runtime.suppressValue((lineno = 68, colno = 82, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Политика конфиденциальности"])), env.opts.autoescape);
output += "</a>\n            </div>\n        </div>\n\n";
output += "\n";
output += "\n";
output += "\n    </div>\n</div>\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})})})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["assets/app/front/components/Footer/Footer.jinja"] , dependencies)